$(document).ready(function() {
	$('#prod-gallery').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: true,
		dots: false,
		fade: true,
		rtl: ($("html").attr('dir') == 'rtl' ? true : false),
		autoplay: false,
		infinite: true,
		asNavFor: '#prod-gallery-navi',
		prevArrow: '.prod-gallery__arrow-right',
		nextArrow: '.prod-gallery__arrow-left'
	});
	$('#prod-gallery-navi').slick({
		slidesToShow: 5,
		slidesToScroll: 1,
		asNavFor: '#prod-gallery',
		dots: false,
		arrows: false,
		focusOnSelect: true,
		infinite: true,
		// for vertical gallery change false on true
		vertical: false,
		verticalSwiping: false,
		// for vertical gallery  add next string to comment
		rtl: ($("html").attr('dir') == 'rtl' ? true : false ),
		responsive: [{
				breakpoint: 768,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
					// for vertical gallery uncomment strings above
					// vertical: false,
					// verticalSwiping: false,
					// rtl: ($("html").attr('dir') == 'rtl' ? true : false),
				}
			},
			{
				breakpoint: 350,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
					// for vertical gallery uncomment strings above
					// vertical: false,
					// verticalSwiping: false,
					// rtl: ($("html").attr('dir') == 'rtl' ? true : false),
				}
			}
		]
	});

	$('.prod-gallery-lightbox').lightGallery({
		thumbnail: true,
		selector: '.prod-gallery__img-big'
	});

});